import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import "@fontsource/roboto"; 
import "@fontsource/poppins";
import './src/styles/reset.css'
import './src/styles/common.css'
import './src/styles/style.css'
import "bootstrap/dist/css/bootstrap.min.css";


export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    {element}
  </PrismicProvider>
)